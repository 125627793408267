// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierTypeListHandleAction(
    $actionId: ID!,
    $selectedIds: [ID!],
    $context: String
  ) {
    successful: dossierTypeListHandleAction(
      actionId: $actionId,
      dossierIds: $selectedIds,
      context: $context
    )
  }
`;

export default MUTATION;
